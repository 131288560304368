import React from 'react'
import Navbar from './Navbar'

const AccessDenied = () => {
  return (
    <>
      <Navbar />
      {/* <Banner /> */}
      <div className='container mt-4'>
        <div className='content m-3'>
          <h3 className='text text-dark fw-semibold fs-3 mb-3'>Access Denied</h3>
          <p className='text text-secondary fs-6'>Please enter a valid unique key</p>
          <a href='/'>Home Page</a><i className="ms-2 bi bi-link"></i>
        </div>
      </div>
    </>
  )
}

export default AccessDenied