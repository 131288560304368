export class DateTimeManager {

    constructor(datetime) {

        //var dateTime = new Date(datetime);

        //var t = Date.UTC(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(),
        //    dateTime.getHours(), dateTime.getMinutes(), dateTime.getSeconds())
        //    + (dateTime.getTimezoneOffset() * 60 * 1000);

        //this.date = new Date(t);
        const date = new Date(datetime);

        if (date instanceof Date && !isNaN(date.valueOf())) {
            this.date = date;
        }
        else {
            this.date = null;
        }
    }

	getDateGermanFormat() {
        return ('0' + this.date.getDate()).slice(-2) + '.' +
            ('0' + parseInt(this.date.getMonth() + 1)).slice(-2) + '.' +
            this.date.getFullYear();
	}

	getTimeDefaultFormat() {
		return ('0' + this.date.getHours()).slice(-2) + ':' +
			('0' + this.date.getMinutes()).slice(-2);
	}

	getDateTimeGermanFormat() {
        return ('0' + this.date.getDate()).slice(-2) + '.' +
            ('0' + parseInt(this.date.getMonth() + 1)).slice(-2) + '.' +
            this.date.getFullYear() + ' ' +
            ('0' + this.date.getHours()).slice(-2) + ':' +
            ('0' + this.date.getMinutes()).slice(-2);
    }

    getDefaultDateFormat() {
        return this.date.getFullYear() + "-" + ('0' + parseInt(this.date.getMonth() + 1)).slice(-2) + "-" + ('0' + this.date.getDate()).slice(-2);
    }

    getDefaultDateTimeFormat() {
        return this.date.getFullYear() + "-" + ('0' + parseInt(this.date.getMonth() + 1)).slice(-2) + "-" + ('0' + this.date.getDate()).slice(-2) + "T" +
            ('0' + this.date.getHours()).slice(-2) + ':' + ('0' + this.date.getMinutes()).slice(-2) + ':' + ('0' + this.date.getSeconds()).slice(-2);
    }
}