import React from 'react'
import Banner from './Banner'
import Navbar from './Navbar'

const PageNotFound = () => {
  return (
    <>
      <Navbar />
      <div className='container mt-4'>
        <div className='content m-3'>
          <h3 className='text text-dark fw-semibold fs-3 mb-3'>404 Page Not Found</h3>
          <p className='text text-secondary fs-6'>This route does not exist. Please try again.</p>
        </div>
      </div>
    </>
  )
}

export default PageNotFound