import React, { useEffect, useRef, useState } from 'react';
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library';
import { useNavigate } from 'react-router-dom';

const QRScanner = () => {
  const [show, setShow] = useState(false);
  const [result, setResult] = useState("");
  const videoRef = useRef(null);
  const codeReader = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (result != "") {
      navigate("contact/" + result);
    }
  }, [result])

  useEffect(() => {
    codeReader.current = new BrowserMultiFormatReader();

    if (show) {
      codeReader.current
        .decodeFromInputVideoDevice(undefined, videoRef.current)
        .then((result) => {
          setResult(result.text);
        })
        .catch((error) => {
          if (!(error instanceof NotFoundException)) {
            console.error(error);
          }
          setResult("");
        });
    }

    return () => {
      codeReader.current.reset();
    };
  }, [show]);

  const startScanning = () => {
    setResult("");
    setShow(true);
  };

  const stopScanning = () => {
    setResult("");
    setShow(false);
  };

  return (
    <>
      {show ? (
        <>
          <div className='mb-1'>
            <video ref={videoRef} style={{ width: '100%' }} autoPlay playsInline muted />
          </div>
          <div>
            <button className='add-lead mb-3' target='_blank' onClick={stopScanning}>Scan abbrechen</button>
          </div>
        </>
      ) : (
        <>
          <div>
            <button className='add-lead mb-3' target='_blank' onClick={startScanning}><p className='m-0'><i className="bi bi-qr-code-scan me-2 text-light"></i> Scan starten</p></button>
          </div>
        </>
      )}
      {result && <p>Result: {result}</p>}
    </>
  );
}

export default QRScanner;